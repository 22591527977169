import { memo } from 'react';
import { useTheme } from 'styled-components';

import { Flexbox, Typography } from '../../atoms';
import { AppFooterMobile } from '../../templates';
import type { AppFooterMobileProps, HeaderLinksInterface } from '../../templates';

import { NavLinks } from './nav-links';
import { TitleWrapper, Wrapper, StyledCloseIcon, StopBodyScroll } from './styled-components';

const FIXED_LINKS = [
  { text: 'Help', pageName: 'Help', href: '/help' },
  {
    text: 'Contact Us',
    pageName: 'Contact Us',
    href: '/contact',
  },
];

interface MenuProps {
  links: HeaderLinksInterface[];
  legalLinks: AppFooterMobileProps['legalLinks'];
  isVisible: boolean;
  onClose: () => void;
}

export const MobileMenu = memo(({ links, legalLinks, isVisible, onClose }: MenuProps) => {
  const theme = useTheme();

  return (
    <Wrapper isVisible={isVisible} id="mobile-menu">
      {isVisible && <StopBodyScroll />}

      <div>
        <TitleWrapper>
          <Typography weight="semibold" variant="h4" color={theme.palette.text.onSurface.strong}>
            Menu
          </Typography>
          <StyledCloseIcon name="Close" width="16" height="16" onClick={onClose} />
        </TitleWrapper>
        <Flexbox as="div">
          <NavLinks links={links.concat(FIXED_LINKS)} onLinkClick={onClose} isMobile={isVisible} />
        </Flexbox>
      </div>

      <AppFooterMobile legalLinks={legalLinks} />
    </Wrapper>
  );
});

MobileMenu.displayName = 'MobileMenu';
