import { omit } from 'rambda';
import type { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import type { NextImageProps } from '@seuk/design-system/atoms';
import { NextImage, Container } from '@seuk/design-system/atoms';

const HeroContainer = styled.section<{ backgroundUrl?: string; viewHeight?: number }>`
  ${({ theme, viewHeight }) => css`
    box-sizing: border-box;
    position: relative;

    ${theme.breakpoints.up('lg')} {
      min-height: ${viewHeight ? `${viewHeight}vh` : '45vh'};
    }
  `}
`;

const ImageContainer = styled.div<{ backgroundUrl?: string }>`
  position: relative;

  height: 18.125rem;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: auto;
    position: static;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    align-items: flex-start;
  }
`;

type BackgroundAlignment =
  | 'right center'
  | 'right top'
  | 'right bottom'
  | 'left center'
  | 'left top'
  | 'left bottom'
  | 'center top'
  | 'center bottom'
  | 'center center';

interface HeroProps {
  heroImage: NextImageProps;
  heroAltText: string;
  bgAlign?: BackgroundAlignment;
  viewHeight?: number;
  id?: string;
}

export const Hero = ({ heroImage, heroAltText, bgAlign, children, viewHeight, id }: PropsWithChildren<HeroProps>) => (
  <HeroContainer {...{ id, viewHeight }}>
    <ContentContainer>{children}</ContentContainer>
    <ImageContainer>
      <NextImage
        {...omit(['width', 'height'], heroImage)}
        alt={heroAltText}
        priority
        quality={100}
        fill
        style={{
          objectFit: 'cover',
          objectPosition: bgAlign,
        }}
      />
    </ImageContainer>
  </HeroContainer>
);
