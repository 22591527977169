import Link from 'next/link';
import styled from 'styled-components';

import { Icon } from '@seuk/design-system/atoms';
import { isDocumentUrl, isExternalUrl } from '@seuk/util';

import { Container } from '../../atoms/container';
import { ShellEnergyLogo } from '../../atoms/shell-energy-logo';

import { Copyright } from './copyright';

interface LinkItem {
  label: string;
  href: string;
}

interface FooterSection {
  title: string;
  items: readonly LinkItem[];
}

export interface AppFooterProps {
  background?: 'white' | 'grey';
  sections?: readonly FooterSection[];
  legalLinks: readonly LinkItem[];
}

const StyledFooter = styled.footer<{ background: string }>`
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  background: ${({ theme, background }) =>
    background === 'white' ? theme.palette.background.surface : theme.palette.background.base};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${({ theme }) => theme.spacing(6)};
    padding-bottom: ${({ theme }) => theme.spacing(6)};
    margin-top: auto;
  }
`;

const FirstRow = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`;

const ShellLogoContainer = styled.div`
  height: 40px;
  > * {
    height: 100%;
    width: auto;
  }
`;

const OtherLinks = styled.div`
  display: grid;
  gap: 1.5rem;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

const LegalLinksRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  font-size: 14px;
  justify-content: space-between;
  ${OtherLinks} + & {
    margin-top: ${({ theme }) => theme.spacing(16)};
  }
  padding-top: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.palette.text.onSurface.subtle};
  border-top: 1px solid ${({ theme }) => theme.palette.text.onSurface.subtle};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    align-items: center;
  }
`;

const LegalLinks = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 1rem;
  }

  a {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.onSurface.subtle};
    ${({ theme }) => theme.breakpoints.up('sm')} {
      &:not(:first-child) {
        margin-left: ${({ theme }) => theme.spacing(4)};
      }
    }
  }
`;

const LinksSection = styled.div``;

const LinksSectionTitle = styled.div`
  font-family: ShellHeavy;
  font-size: 18px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const CookiePreferences = styled.button`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.onSurface.subtle};
  cursor: pointer;
  text-decoration: underline;
  background: none;
  border: none;
  ${({ theme }) => theme.typography.paragraph.small.normal};
`;

declare const evidon: {
  notice: {
    showOptions: () => void;
  };
};

const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.palette.text.onSurface.strong};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const AppFooter = ({ background, sections, legalLinks }: AppFooterProps) => {
  return (
    <StyledFooter background={background || 'grey'}>
      <Container>
        <FirstRow>
          <ShellLogoContainer>
            <ShellEnergyLogo />
          </ShellLogoContainer>
        </FirstRow>
        {!!sections && (
          <OtherLinks>
            {sections.map(({ items, title }) => (
              <LinksSection key={title}>
                <LinksSectionTitle>{title}</LinksSectionTitle>
                {items.map((item) =>
                  isExternalUrl(item.href) ? (
                    <StyledLink as="a" href={item.href} key={item.href}>
                      {item.label}
                    </StyledLink>
                  ) : (
                    <StyledLink key={item.href} href={item.href ?? ''} prefetch={false}>
                      {item.label}
                    </StyledLink>
                  )
                )}
              </LinksSection>
            ))}
          </OtherLinks>
        )}

        <LegalLinksRow>
          <LegalLinks>
            <CookiePreferences
              data-testid="cookie-preference"
              onClick={() => {
                if (evidon !== undefined) {
                  evidon.notice?.showOptions();
                }
              }}
            >
              Manage my cookies
            </CookiePreferences>
            {legalLinks.map((link) => (
              <Link
                href={link.href}
                prefetch={false}
                key={link.href}
                {...(isDocumentUrl(link.href) || isExternalUrl(link.href)
                  ? { target: '_blank', rel: 'noopener noreferrer' }
                  : {})}
              >
                <Icon name="Documents" /> {link.label}
              </Link>
            ))}
          </LegalLinks>
        </LegalLinksRow>

        <Copyright />
      </Container>
    </StyledFooter>
  );
};
