import type { ReactElement, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { Container as ContainerComponent } from '../../atoms/container';
import type { IconName } from '../../atoms/icon';
import { Icon } from '../../atoms/icon';

const Wrapper = styled.section<{ backgroundColor?: string }>`
  ${({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor ?? theme.palette.accent.primary.strong};
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(3)};
  `}
`;

const Container = styled(ContainerComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const NotificationIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.onSurface.strong};
    fill: ${theme.palette.text.onSurface.strong};
    margin-right: ${theme.spacing(2.5)};
    min-width: 20px;
    min-height: 20px;
  `}
`;

const CloseIcon = styled(Icon)`
  ${({ theme }) => css`
    min-width: 12px;
    min-height: 12px;
    fill: ${theme.palette.text.onSurface.strong};
    margin-left: ${theme.spacing(2.5)};
    margin-right: ${theme.spacing(2.5)};
  `};
`;

const CloseButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: auto;
`;

const NotificationText = styled.div`
  ${({ theme }) => css`
    ${theme.typography.paragraph.medium.normal};
    color: ${theme.palette.text.onSurface.strong};

    p {
      margin: 0;
    }

    a {
      ${theme.typography.label.large};
      white-space: nowrap;
      color: ${theme.palette.background.surface};
    }
  `}
`;

export interface NotificationBarProps {
  icon?: IconName;
  children: ReactNode;
  backgroundColor?: string;
}

export function NotificationBar({
  icon = 'ErrorOutline',
  children,
  backgroundColor,
}: NotificationBarProps): ReactElement | null {
  const { palette } = useTheme();
  const [isVisible, setIsVisible] = useState(true);
  const onCloseButtonClick = useCallback(() => setIsVisible(false), [setIsVisible]);

  if (isVisible) {
    return (
      <Wrapper backgroundColor={backgroundColor} data-testid="notification-bar">
        <Container role="alert">
          <NotificationIcon name={icon} color={palette.background.surface} height="20" width="20" />
          <NotificationText>{children}</NotificationText>
          <CloseButton name="close-notification-bar" aria-label="close" onClick={onCloseButtonClick}>
            <CloseIcon name="Close" color={palette.background.surface} height="12" width="12" />
          </CloseButton>
        </Container>
      </Wrapper>
    );
  }

  return null;
}
