const URL_PROTOCOL_REGEX = /^https?:\/\//i;
export const isExternalUrl = (url: string, baseHostname?: string): boolean =>
  URL_PROTOCOL_REGEX.test(url) && (!baseHostname || new URL(url).hostname !== baseHostname);

export const isMailTo = (url: string): boolean => url.startsWith('mailto:');

export const isDocumentUrl = (url: string): boolean => url.endsWith('.pdf');

export const isUrl = (url: string): boolean => {
  let result = true;
  try {
    new URL(url);
  } catch (e) {
    result = false;
  }

  return result;
};

export const isCMSImageCDNUrl = (url: string): boolean => /^(https)?:?\/\/images\.ctfassets\.net/i.test(url);
