import type { ReactNode } from 'react';
import { useRef, useEffect } from 'react';
import styled from 'styled-components';

const CollapseWrapper = styled.div<{ isOpen?: boolean }>`
  overflow: hidden;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  height: 0px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  @media (prefers-reduced-motion: reduce) {
    transition-duration: 0;
  }
`;

export interface CollapseProps {
  isOpen?: boolean;
  children?: ReactNode;
}

export const Collapse = ({ isOpen, children }: CollapseProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const setContainerHeight = (height: 'auto' | number) => {
    if (containerRef.current && contentRef.current) {
      containerRef.current.style.height = height === 'auto' ? height : `${height}px`;
      // WARNING!!! Do not remove 'containerRef.current.clientHeight'
      // It is needed to make browser recalculate height after assignment above.
      // Otherwise browser will skip this value
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      containerRef.current.clientHeight;
    }
  };

  useEffect(() => {
    const contentHeight = contentRef.current?.clientHeight ?? 0;
    if (isOpen) {
      setContainerHeight(contentHeight);
    } else {
      setContainerHeight(contentHeight);
      setContainerHeight(0);
    }
  }, [isOpen]);

  const transitionEndHandler = () => {
    if (contentRef.current?.clientHeight === containerRef.current?.clientHeight) {
      setContainerHeight('auto');
    }
  };

  return (
    <CollapseWrapper
      ref={containerRef}
      onTransitionEnd={transitionEndHandler}
      className="overflow-hidden h-0 transition-height duration-300 ease-in-out"
      isOpen={isOpen}
    >
      <div ref={contentRef}>{children}</div>
    </CollapseWrapper>
  );
};
