import Link from 'next/link';
import styled, { css } from 'styled-components';

type Color = 'primary' | 'secondary' | 'tertiary';
const primaryStyles = css`
  ${({ theme }) => css`
    background-color: ${theme.palette.accent.primary.strong};
    border-color: ${theme.palette.accent.primary.strong};

    &:hover {
      background-color: ${theme.palette.accent.primary.medium};
      border-color: ${theme.palette.accent.primary.medium};
    }

    &:active {
      background-color: ${theme.palette.accent.primary.subtle};
      border-color: ${theme.palette.accent.primary.subtle};
    }
  `}
`;

const secondaryStyles = css`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.surface};
    border-color: ${theme.palette.border.strong};

    &:hover {
      background-color: ${theme.palette.focus.subtle};
      border-color: ${theme.palette.focus.strong};
    }

    &:active {
      background-color: ${theme.palette.background.surface};
      border-color: ${theme.palette.border.strong};
    }
  `}
`;

const tertiaryStyles = css`
  ${({ theme }) => css`
    background-color: ${theme.palette.accent.secondary.strong};
    border-color: ${theme.palette.accent.secondary.strong};
    color: white;

    &:hover {
      background-color: ${theme.palette.accent.secondary.medium};
      border-color: ${theme.palette.accent.secondary.medium};
    }

    &:active {
      background-color: ${theme.palette.accent.secondary.medium};
      border-color: ${theme.palette.accent.secondary.medium};
    }
  `}
`;

function getButtonStyles(color?: Color) {
  switch (color) {
    case 'primary':
      return primaryStyles;

    case 'secondary':
      return secondaryStyles;

    case 'tertiary':
      return tertiaryStyles;

    default:
      return primaryStyles;
  }
}

const openNewTabIconStyles = css`
  ${({ theme }) => css`
    position: relative;
    padding-right: ${theme.spacing(12)};

    ${theme.breakpoints.up('sm')} {
      padding-right: ${theme.spacing(12)};
    }

    &::after {
      content: '';
      background-image: url(/icons/new-page.svg);
      position: absolute;
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      margin-top: ${theme.spacing(1)};
      margin-left: ${theme.spacing(2)};
    }
  `}
`;

const baseBtnStyles = css<{ color?: Color; $newPage?: boolean }>`
  ${({ theme, color, $newPage }) => css`
    display: inline-block;
    border: 1px solid;
    cursor: pointer;
    ${theme.typography.label.large.bold}
    color: ${theme.palette.text.onSurface.strong};
    border-radius: ${theme.radius};
    padding: ${theme.spacing(3)};

    ${getButtonStyles(color)};

    &:disabled {
      cursor: not-allowed;
      color: ${theme.palette.text.onSurface.subtle};
      background-color: ${theme.palette.text.onSurface.inactive};
      border-color: ${theme.palette.text.onSurface.inactive};
    }

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(3)} ${theme.spacing(7.5)};
    }

    ${$newPage ? openNewTabIconStyles : ''}
  `}
`;

const StyledButton = styled.button<{ color?: Color; fullWidthOnMobile?: boolean }>`
  ${baseBtnStyles}

  ${({ theme, fullWidthOnMobile }) =>
    fullWidthOnMobile
      ? `
    width: 100%;
    ${theme.breakpoints.up('md')} {
      width: unset;
    }
  `
      : ''}
`;
StyledButton.defaultProps = {
  fullWidthOnMobile: true,
};

const AnchorCSS = css`
  ${baseBtnStyles}
  text-decoration: none;
`;

const StyledAnchor = styled.a<{ color?: Color }>`
  ${AnchorCSS}
`;

const StyledAnchorNext = styled(Link)<{ color?: Color }>`
  ${AnchorCSS}
`;

export const Button = StyledButton;
export const ButtonLink = StyledAnchor;
export const ButtonLinkNext = StyledAnchorNext;

/**
 * A button that looks like a link but actually a button
 */
export const LinkButton = styled.button.attrs({ type: 'button' })<{ isDataNotYetAvailable?: boolean }>`
  ${({ theme, isDataNotYetAvailable }) => css`
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    color: ${theme.palette.focus.strong};
    ${theme.typography.label.extraLarge};
    visibility: ${isDataNotYetAvailable ? 'hidden' : 'visible'};
  `}
`;
