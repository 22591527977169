import { useTheme } from 'styled-components';

import { Typography } from '../../atoms';

import { DropDownNav } from './drop-down-nav';
import { NavLink } from './nav-link';
import { IconContainer, NavigationMobileMyAccountButton, StyledIcon, WebsiteSections } from './styled-components';

import type { HeaderLinksInterface } from '.';

export interface NavLinksProps {
  links: HeaderLinksInterface[];
  isMobile?: boolean;
  onLinkClick?: () => void;
}

export function NavLinks({ links, isMobile = false, onLinkClick }: NavLinksProps) {
  const theme = useTheme();

  return (
    <WebsiteSections>
      {links.map((link) => {
        if (link?.subItems?.length) {
          return (
            <DropDownNav key={link.href ?? link.pageName} link={link} isMobile={isMobile} onLinkClick={onLinkClick} />
          );
        } else {
          return (
            <NavLink
              key={link.href ?? link.pageName}
              isSubMenu={false}
              link={link}
              isMobile={isMobile}
              onLinkClick={onLinkClick}
            />
          );
        }
      })}
      {isMobile && (
        <li>
          <NavigationMobileMyAccountButton as="a" href={process.env['NEXT_PUBLIC_ACCOUNT_URL'] ?? '/'}>
            <IconContainer>
              <StyledIcon name="Customer" width="32" height="32" />
            </IconContainer>
            <Typography weight="semibold" variant="h5" color={theme.palette.text.onSurface.strong}>
              My Account login
            </Typography>
          </NavigationMobileMyAccountButton>
        </li>
      )}
    </WebsiteSections>
  );
}
