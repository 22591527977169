import React from 'react';
import styled, { css } from 'styled-components';

import { Container, NextImage } from '@seuk/design-system/atoms';
import { cmsImageLoader } from '@seuk/util';

import { getChevronUrl } from './styled-components';

const Section = styled.section`
  background-color: ${({ theme }) => theme.palette.background.base};
`;

const RegionSelectorWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 ${theme.spacing(4)};

    ${theme.breakpoints.down('md')} {
      justify-content: center;
    }
  `}
`;

const DropdownOptions = styled.div`
  ${({ theme }) => css`
    position: absolute;
    z-index: 3;
    top: ${theme.spacing(11)};
    right: -${theme.spacing(6)};
    width: ${theme.spacing(50)};
    background-color: white;
    border: 1px solid ${theme.palette.text.onSurface.inactive};
    visibility: collapse;
    opacity: 0;
    transition: 0.2s ease opacity;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: ${theme.spacing(4)} 0;
        border-bottom: 1px solid ${theme.palette.text.onSurface.inactive};

        &:hover {
          background-color: ${theme.palette.background.base};
        }

        img {
          margin-right: ${theme.spacing(4)};
        }
      }
    }
  `}
`;

const CurrentCountryContent = styled.span`
  ${({ theme }) => css`
    padding: ${theme.spacing(3)} ${theme.spacing(4)};
    cursor: pointer;
    position: relative;

    &:hover {
      ${DropdownOptions} {
        visibility: visible;
        opacity: 1;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      background-image: url(${getChevronUrl(theme.palette.text.onSurface.subtle)});
      background-position: right ${theme.spacing(2)} center;
      background-repeat: no-repeat;
      background-size: ${theme.spacing(6)} ${theme.spacing(6)};
      appearance: none;
      padding-left: ${theme.spacing(4)};
    }
  `}
`;

const OptionLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacing(6)};
    white-space: nowrap;
    text-decoration: none;
    color: ${theme.palette.text.onSurface.strong};
  `}
`;

const COUNTRIES = [
  { name: 'Australia', code: 'au', link: 'https://shellenergy.com.au/' },
  { name: 'Germany', code: 'de', link: 'https://www.shell.de/energiepartner' },
  { name: 'United States', code: 'us', link: 'https://shellenergy.com/' },
];

export default function RegionSelector() {
  return (
    <Section>
      <Container>
        <RegionSelectorWrapper>
          <NextImage
            src="/flags/gb.svg"
            loader={cmsImageLoader}
            alt="UK-flag"
            width="30"
            height="20"
            unoptimized={true}
            aria-hidden={true}
          />
          <CurrentCountryContent>
            United Kingdom
            <DropdownOptions>
              <ul>
                {COUNTRIES.map((country) => (
                  <li key={country.name}>
                    <OptionLink href={country.link} rel="noopener noreferrer" target="_blank">
                      <NextImage
                        src={`/flags/${country.code}.svg`}
                        loader={cmsImageLoader}
                        alt={country.name}
                        width="30"
                        height="20"
                        unoptimized={true}
                        aria-hidden={true}
                      />
                      <span>{country.name}</span>
                    </OptionLink>
                  </li>
                ))}
              </ul>
            </DropdownOptions>
          </CurrentCountryContent>
        </RegionSelectorWrapper>
      </Container>
    </Section>
  );
}
