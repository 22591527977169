import Link from 'next/link';
import { memo, useCallback, useState } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import { type ArrayElement } from '@seuk/util';

import type { IconName } from '../../atoms';
import { Container } from '../../atoms';
import { Icon } from '../../atoms/icon';
import { ShellEnergyLogo } from '../../atoms/shell-energy-logo';

import BurgerIcon from './burger-icon';
import { MobileMenu } from './mobile-menu';
import { NavLink } from './nav-link';
import { NavLinks } from './nav-links';
import RegionSelector from './region-selector';
import { WebsiteSections } from './styled-components';

const MOBILE_VIEW_BREAKPOINT: ArrayElement<DefaultTheme['breakpoints']['keys']> = 'lg';

export const MOBILE_HEADER_SPACING = 16;

const StyledHeader = styled.header`
  ${({ theme }) => css`
    z-index: 2;
    display: flex;
    top: 0;
    background: white;
    padding: ${theme.spacing(4)} ${theme.spacing(4)} 0;
    box-shadow: ${theme.shadow(2)};
    align-items: stretch;
    justify-content: space-between;

    ${theme.breakpoints.down('lg')} {
      padding: ${theme.spacing(4)};
      justify-content: stretch;
    }
  `}
`;

const DesktopHeaderContainer = styled(Container)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: end;

    ${theme.breakpoints.down('lg')} {
      max-width: 100%;
      flex-direction: row;
      margin: 0;
      justify-content: space-between;
    }
  `}
`;

const DesktopHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DesktopHeaderBottom = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-top: 1px solid ${theme.palette.border.medium};

    ${theme.breakpoints.down('lg')} {
      border-top: none;
    }
  `}
`;

const ShellLogoWrapper = styled.div`
  ${({ theme }) => css`
    svg {
      height: 100%;
    }
    ${theme.breakpoints.down('lg')} {
      height: 38px;
    }
  `}
`;

const NavigationButton = styled.a`
  ${({ theme }) => css`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    font-weight: 500;
    font-size: 18px;
    height: 100%;
    text-decoration: none;
    white-space: nowrap;
    color: ${theme.palette.text.onSurface.strong};
    padding: ${theme.spacing(2)};

    &:hover,
    &.active {
      color: black;
    }

    ${theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
      &::after {
        display: none;
        position: absolute;
        content: '';
        border-bottom: 6px solid;
        border-color: ${theme.palette.accent.primary.strong};
        width: 100%;
        bottom: 0;
        left: 0;
      }
      &:hover::after,
      &.active::after {
        display: block;
      }
    }
  `}
`;

const Nav = styled.nav`
  ${({ theme }) => css`
    display: none;
    ${theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
      display: block;
    }
  `}
`;

const AccountButton = styled(NavigationButton)`
  ${({ theme }) => css`
    display: none;
    ${theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
      display: flex;
    }
  `}
`;

const Burger = styled.button`
  background: none;
  border: none;
  font: 400 16px/24px ShellBook;
  color: ${({ theme }) => theme.palette.text.onSurface.strong};
  display: flex;
  align-self: center;
  align-items: center;
  cursor: pointer;
  // to set burger on top of logo layer
  z-index: 1;
  ${({ theme }) => theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
    display: none;
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const MyAccountLabel = styled.span`
  ${({ theme }) => css`
    ${theme.typography.heading[4].bold}
    ${theme.breakpoints.down('lg')} {
      display: none;
    }
  `}
`;

export interface HeaderLinksInterface {
  pageName: string;
  icon?: IconName;
  text: string;
  href?: string;
  active?: boolean;
  subItems?: {
    pageName: string;
    icon?: IconName;
    text: string;
    href: string;
    active?: boolean;
  }[];
}

interface LinkItem {
  label: string;
  href: string;
}

export interface AppHeaderProps {
  links: HeaderLinksInterface[];
  legalLinks: readonly LinkItem[];
  logoHref?: string;
}

export function AppHeaderComponent({ links, legalLinks, logoHref = '/' }: AppHeaderProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openMobileMenu = useCallback(() => setIsMobileMenuOpen(true), []);
  const closeMobileMenu = useCallback(() => setIsMobileMenuOpen(false), []);

  const Logo = (
    <ShellLogoWrapper>
      <Link href={logoHref} passHref>
        <ShellEnergyLogo />
      </Link>
    </ShellLogoWrapper>
  );

  return (
    <>
      <RegionSelector />
      <StyledHeader>
        <DesktopHeaderContainer>
          {links.length > 0 && (
            <Burger
              type="button"
              onClick={openMobileMenu}
              aria-expanded={isMobileMenuOpen}
              aria-controls="mobile-menu"
              aria-label="mobile-menu"
            >
              <BurgerIcon />
            </Burger>
          )}
          <DesktopHeaderTop>
            {Logo}
            <Nav>
              <WebsiteSections>
                <NavLink link={{ text: 'Help', pageName: 'Help', href: '/help' }} />
                <NavLink
                  link={{
                    text: 'Contact Us',
                    pageName: 'Contact Us',
                    href: '/contact',
                  }}
                />
              </WebsiteSections>
            </Nav>
          </DesktopHeaderTop>

          <DesktopHeaderBottom>
            <Nav role="navigation">
              <NavLinks links={links} />
            </Nav>

            <AccountButton aria-label="My account" href={process.env['NEXT_PUBLIC_ACCOUNT_URL'] ?? '/'}>
              <Icon name="Account" width="32" height="32" style={{ marginRight: 5 }} />
              <MyAccountLabel>My Account login</MyAccountLabel>
            </AccountButton>
          </DesktopHeaderBottom>
        </DesktopHeaderContainer>
      </StyledHeader>

      <MobileMenu {...{ links, legalLinks, isVisible: isMobileMenuOpen, onClose: closeMobileMenu }} />
    </>
  );
}

export const AppHeader = memo(AppHeaderComponent);
