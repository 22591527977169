const BurgerIcon = () => {
  return (
    <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5078 1.35938C23.9668 1.35938 24.3403 1.73282 24.3403 2.19184C24.3403 2.65087 23.9668 3.02431 23.5078 3.02431H1.83247C1.37344 3.02431 1 2.65087 1 2.19184C1 1.73282 1.37344 1.35938 1.83247 1.35938H23.5078ZM23.5078 8.02691C23.9668 8.02691 24.3403 8.40035 24.3403 8.85938C24.3403 9.3184 23.9668 9.69184 23.5078 9.69184H1.83247C1.37344 9.69184 1 9.3184 1 8.85938C1 8.40035 1.37344 8.02691 1.83247 8.02691H23.5078ZM23.5078 14.6944C23.9668 14.6944 24.3403 15.0679 24.3403 15.5269C24.3403 15.9859 23.9668 16.3594 23.5078 16.3594H1.83247C1.37344 16.3594 1 15.9859 1 15.5269C1 15.0679 1.37344 14.6944 1.83247 14.6944H23.5078Z"
        fill="#404040"
        stroke="#404040"
      />
    </svg>
  );
};
export default BurgerIcon;
