import autoAnimate from '@formkit/auto-animate';
import type { PropsWithChildren } from 'react';
import { useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;
interface AnimatedContainerProp {
  className?: string;
}
export const AnimatedContainer = ({ children, ...rest }: PropsWithChildren<AnimatedContainerProp>) => {
  const parent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <Container ref={parent} {...rest}>
      {children}
    </Container>
  );
};
