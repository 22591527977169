import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import 'react-phone-number-input/style.css';

import { theme as defaultTheme } from '@seuk/design-system/theme';

import '../fonts.css';
import '../global.css';
import CustomApp from '../modules/custom/custom-app';
import useScrollRestoration from '../util/hooks/scrollRestoration';
import { usePageAnalytics } from '../util/hooks/usePageAnalytics';

type Props = AppProps<{ translations: Translations }>;

function App(props: Props) {
  useScrollRestoration();
  usePageAnalytics();

  return (
    <ThemeProvider theme={defaultTheme}>
      <CustomApp {...props} />
    </ThemeProvider>
  );
}

export default App;
