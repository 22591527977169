import Image from 'next/image';
import type { ImageProps } from 'next/image';
import { memo, useContext } from 'react';

import { BasePathContext, cmsImageLoader } from '@seuk/util';

export type { ImageProps as NextImageProps };

export const NextImage = memo((props: ImageProps) => {
  const basePath = useContext(BasePathContext);

  const src = `${basePath}${props.src}`;

  return <Image loader={cmsImageLoader} {...props} src={src} />;
});
NextImage.displayName = 'NextImage';
