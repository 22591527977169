import type { NextSeoProps } from 'next-seo';

const SITE_NAME = 'Shell Energy UK';
export const WEBSITE_SEO: NextSeoProps = {
  defaultTitle: SITE_NAME,
  titleTemplate: `%s | ${SITE_NAME}`,
  openGraph: {
    type: 'website',
    siteName: SITE_NAME,
  },
  additionalMetaTags: [
    {
      name: 'apple-mobile-web-app-title',
      content: SITE_NAME,
    },
    {
      name: 'application-name',
      content: SITE_NAME,
    },
    {
      name: 'msapplication-TileColor',
      content: '#ffffff',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
    {
      name: 'msapplication-config',
      content: '/quote/small-businesses/favicon/browserconfig.xml',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: '/quote/small-businesses/favicon/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'icon',
      href: '/quote/small-businesses/favicon/favicon-32x32.png',
      type: 'image/png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/quote/small-businesses/favicon/favicon-16x16.png',
      type: 'image/png',
      sizes: '16x16',
    },
    {
      rel: 'mask-icon',
      href: '/quote/small-businesses/favicon/safari-pinned-tab.svg',
      color: '#dd1d21',
    },
    {
      rel: 'manifest',
      href: '/quote/small-businesses/favicon/site.webmanifest',
    },
  ],
};

// todo change to real seo (?)
export const QUOTE_SEO: NextSeoProps = {
  ...WEBSITE_SEO,
  description:
    'Energy supply and solutions from Shell Energy. Flexible, fixed and passthrough gas, power and renewable energy for UK businesses.',
};
