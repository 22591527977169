import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { isServerSide } from '@seuk/util';

declare global {
  interface Window {
    digitalData: unknown[];
  }
}

const DEFAULT_TITLE = 'Shell Energy Business';

const getPageName = () => {
  if (document.title !== DEFAULT_TITLE) {
    return document.title;
  }

  // if page title is not filled in CMS, then use content title
  return document.querySelector('h1')?.textContent ?? 'unknown page';
};

const track = (pageName: string) => {
  if (isServerSide()) {
    return;
  }

  window.digitalData = window.digitalData || [];
  window.digitalData.push({
    event: 'Page Loaded',
    metric: 'attract',
    timestamp: Number(new Date()),
    page: {
      pageName,
      platform: 'web',
    },
  });
};

export const usePageAnalytics = () => {
  const router = useRouter();

  useEffect(() => {
    track(getPageName());
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      track(getPageName());
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
};
