/**
 * Try to cast value to enum
 * @param targetEnum enum to lookup the value in
 * @param value
 * @returns either enum value or undefined if no value is found
 */
export function valueToEnum<TEnum extends object>(
  targetEnum: TEnum,
  value?: string | number
): TEnum[keyof TEnum] | undefined {
  return Object.values(targetEnum).find((enumValue) => enumValue === value);
}
