import { LightMode, Typography } from '@sede-x/shell-ds-fabrics';

import { createBreakpoints, breakpointFromProperty } from './breakpoints';
import { elevation } from './elevation';
import { shadows } from './shadow';

const breakpoints = createBreakpoints();

type Elevation = 0 | 1 | 2 | 3;

export const theme = {
  radius: '4px',
  radiusLg: '9px',
  radiusXl: '13px',
  palette: LightMode,
  typography: Typography,
  breakpoints,
  breakpointFromProperty,
  spacing: (step: number) => `${step * 0.25}rem`,
  elevation: (level: Elevation) => elevation[level],
  shadow: (level: number) => shadows[level],
  // Generate styling for shell "notch" (mostly for headings)
  notch: () => `
    &::after{
      content: '';
      display: block;
      height: 10px;
      width: 50px;
      margin-top: 0.75rem;
      background-color: ${LightMode.accent.primary.strong};
      border-radius: 4px;
    }
  `,
  screenReadersOnly: `
    & {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  `,
};

export type SEUKTheme = typeof theme;
