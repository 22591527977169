import styled, { createGlobalStyle, css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import type { ArrayElement } from '@seuk/util';

import { Flexbox } from '../../atoms';
import { Icon } from '../../atoms/icon';

const MOBILE_VIEW_BREAKPOINT: ArrayElement<DefaultTheme['breakpoints']['keys']> = 'lg';

export const StopBodyScroll = createGlobalStyle`
  ${({ theme }) => theme.breakpoints.down(MOBILE_VIEW_BREAKPOINT)} {
    body {
        touch-action: none;
        -webkit-overflow-scrolling: auto;
        overflow: hidden !important;
        overscroll-behavior: none;
    }
  }
`;

export const WebsiteSections = styled.ul`
  gap: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  list-style: none;
  padding: 0 0 ${({ theme }) => theme.spacing(4)};
  margin: 0;
  ${({ theme }) => theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
    flex-direction: row;
    gap: 0;
    padding: 0;
  }
`;
export function getChevronUrl(color: string) {
  return (
    "\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='" +
    encodeURIComponent(color) +
    "' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e\""
  );
}

export const NavigationButtonLinkStyles = (theme: DefaultTheme, noPadding = false, isActive = false) => css`
  box-sizing: border-box;
  background: ${theme.palette.background.base};
  border-radius: ${theme.radius};
  height: ${theme.spacing(11)};
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: ${theme.palette.background.base};
  }

  ${theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
    ${theme.typography.label.large}
    background: ${theme.palette.background.surface};
    align-items: center;
    position: relative;
    height: 100%;
    text-decoration: none;
    white-space: nowrap;
    color: ${theme.palette.text.onSurface.strong};

    ${isActive
      ? `background-color: ${theme.palette.background.surface};
    color: black;`
      : ''}
    padding: ${theme.spacing(6)} ${noPadding ? 0 : theme.spacing(6)};
    &::after {
      display: none;
      position: absolute;
      content: '';
      border-bottom: 6px solid;
      border-color: ${theme.palette.accent.primary.strong};
      width: 100%;
      bottom: 0;
      left: 0;
      ${isActive ? `display: block;` : ''}
    }
  }
`;

export const OpenNewTabIcon = styled.span`
  ${({ theme }) => css`
    position: relative;
    height: 100%;
    padding-right: ${theme.spacing(12)};

    ${theme.breakpoints.up('sm')} {
      padding-right: ${theme.spacing(12)};
    }

    &::after {
      content: '';
      background-image: url(/icons/new-page.svg);
      position: absolute;
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      top: 25%;
      right: 30%;
      transform: translateX(-50%);
    }
  `}
`;

export const NavigationButtonLink = styled(Flexbox)<{ noPadding?: boolean; isActive?: boolean }>`
  ${({ theme, noPadding, isActive }) =>
    css`
      position: relative;
      ${NavigationButtonLinkStyles(theme, noPadding, isActive)}
    `};
`;

export const NavigationButtonLinkSubMenu = styled(NavigationButtonLink)<{
  noPadding?: boolean;
  isActive?: boolean;
}>`
  ${({ theme, isActive }) => css`
    ${theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
      padding: ${theme.spacing(2)} ${theme.spacing(1)};
      &:hover,
      &:focus {
        color: ${theme.palette.focus.strong};
        &::after {
          display: none;
        }
      }
      ${isActive &&
      css`
        color: ${theme.palette.focus.strong};
        &::after {
          display: none;
        }
      `}
    }
  `}
`;

export const NavigationButtonTitle = styled.span<{
  noPadding?: boolean;
  isActive?: boolean;
  isOpened?: boolean;
  isMobile?: boolean;
}>`
  ${({ theme, noPadding, isActive, isOpened, isMobile }) => css`
    ${NavigationButtonLinkStyles(theme, noPadding, isActive)};
    cursor: pointer;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-position: right ${theme.spacing(2)} center;
      background-repeat: no-repeat;
      background-size: ${theme.spacing(6)} ${theme.spacing(6)};
      appearance: none;
    }

    ${isOpened &&
    css`
      background-color: ${theme.palette.background.base};
      &:before {
        transform: scaleY(-1);
      }
    `}

    ${theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
      padding-right: ${theme.spacing(10)} !important;
      background-color: ${theme.palette.background.surface};
      background-image: url(${getChevronUrl(theme.palette.text.onSurface.subtle)});
      background-position: right ${theme.spacing(2)} top ${theme.spacing(6)};
      background-repeat: no-repeat;
      background-size: ${theme.spacing(6)} ${theme.spacing(6)};
      appearance: none;

      &:before {
        display: none;
      }

      &:hover {
        background-color: ${theme.palette.background.surface};
        color: ${theme.palette.focus.strong};
        background-image: url(${getChevronUrl(theme.palette.focus.strong)});
        &:before {
          display: none;
        }
      }

      ${isOpened &&
      !isMobile &&
      css`
        background-color: ${theme.palette.background.surface};
        color: ${theme.palette.focus.strong};
        background-image: url(${getChevronUrl(theme.palette.focus.strong)});
        &:before {
          display: none;
        }
      `}
    }
  `}
`;

export const NavigationButtonParent = styled.li<{ isOpened?: boolean }>`
  position: relative;
  ul {
    gap: ${({ theme }) => theme.spacing(2)};
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
  ${({ theme }) => theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
    & > div {
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: ${({ theme }) => theme.spacing(1.5)};
      overflow: hidden;
      border-radius: ${({ theme }) => theme.radius};
      box-shadow: ${({ theme }) => theme.shadow(14)};
    }
    ul {
      gap: 0;
      background: ${({ theme }) => theme.palette.background.surface};
      border-radius: ${({ theme }) => theme.radius};
      padding: ${({ theme }) => theme.spacing(6)};
      min-width: ${({ theme }) => theme.spacing(45)};
    }
  }
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme }) => theme.spacing(5)};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(13)};
`;

export const Wrapper = styled.aside<{ isVisible: boolean }>`
  overflow: auto;
  position: fixed;
  // mobile datepicker is 100
  z-index: 101;
  background: ${({ theme }) => theme.palette.background.surface};
  width: 100vw;
  // Fallback for browsers that don't support dynamic viewport height
  height: 100vh;
  // Using dynamic viewport height to fix issues in browsers with dynamic overlay bars (such as Safari in iOS)
  height: 100dvh;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
  ${({ isVisible }) =>
    !isVisible &&
    css`
      transition: transform 0.2s ease-in-out, opacity 0s 0.2s;
      opacity: 0;
      transform: translateX(-100%);
      visibility: collapse;
    `}

  ${({ theme }) => theme.breakpoints.up(MOBILE_VIEW_BREAKPOINT)} {
    opacity: 0;
    transition: none;
    transform: translateX(-100%);
    visibility: collapse;
  }
  box-sizing: border-box;
`;

export const TitleWrapper = styled(Flexbox)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(10)};
  justify-content: space-between;
  align-items: center;
`;

export const LogoutButton = styled(Flexbox).attrs({ as: 'button' })`
  cursor: pointer;
  background: none;
`;

export const StyledCloseIcon = styled(Icon).attrs({ role: 'button' })`
  cursor: pointer;
`;

export const NavigationMobileMyAccountButton = styled(NavigationButtonLink)`
  background-color: ${({ theme }) => theme.palette.accent.primary.strong};
`;
