import { createContext } from 'react';

/**
 * Converts given string to URL path segment
 * @param value
 * @returns value wrapped in forward slash from both sides where needed
 *
 * @example
 * makePathSegment('foo') // -> '/foo/'
 */
export const makePathSegment = (value: string | undefined): string => {
  if (!value) {
    return '';
  }

  let transformedValue = value;

  if (transformedValue.startsWith('/') === false) {
    transformedValue = `/${transformedValue}`;
  }

  if (transformedValue.endsWith('/') === false) {
    transformedValue = `${transformedValue}/`;
  }

  return transformedValue;
};

/**
 * remove the query from a URL path
 *
 * @param path a url path
 * @returns the path without query parameters
 * @example
 * removeQueryFromPath('/path/to/endpoint?query=true&something=foo') // -> '/path/to/endpoint'
 */
export const removeQueryFromPath = (p?: string) => p?.split('?')[0];

export const removeAnchorFromPath = (p?: string) => p?.split('#')[0];

export const removeIndexHTMLFromPath = (p?: string) => p?.replace('index.html', '');

export const pathToReactRoute = (p?: string) => removeIndexHTMLFromPath(removeQueryFromPath(p));

export const BasePathContext = createContext('');
