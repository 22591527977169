export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

// The breakpoint **start** at this value.
// For instance with the first breakpoint xs: [xs, sm[.
const values = {
  xs: 360, // smaller mobile screens
  sm: 640, // tablets
  md: 768, // small laptop
  lg: 1024, // desktop
  xl: 1168, // large screens
} as const;

type BreakpointValues<T extends number | string> = {
  [key in Breakpoint | 'default']?: T;
};

export const createBreakpoints = () => {
  return {
    // Sorted ASC by size. That's important.
    keys: ['sm', 'md', 'lg', 'xl'] as Breakpoint[],
    values,
    up: (key: Breakpoint) => `@media (min-width:${values[key]}px)`,
    down: (key: Breakpoint) => `@media (max-width:${values[key]}px)`,
  };
};

const breakpoints = createBreakpoints();

export type Breakpoints = typeof breakpoints;

export type BreakpointProperty<T extends number | string> = T | BreakpointValues<T>;

function stringifyProperty<T extends number | string>(cssProperty: string | ((value: T) => string), value: T): string {
  if (typeof cssProperty === 'string') {
    return `${cssProperty}: ${value};`;
  } else {
    return `${cssProperty(value)};`;
  }
}

/**
 * ### breakpointFromProperty
 * I ended up creating this helper to ease the pain around responsive design.
 * Some of the responsive behaviour was very difficult to handle with <BreakpointRenderer> alone.
 *
 * @param cssProperty the CSS property to generate, or a function that evaluates each value and return a CSS rule
 * @param value its value or an object associating all values depending on the breakpoint
 * @returns the generated stylesheet for the property
 * @example
 * interface MyComponentProps {
 *   prop: BreakpointProperty<string>
 * }
 * // allows the following:
 * <MyComponent prop="valueOnAllDisplay" />
 * <MyComponent prop={{ default: 'valueOnMobileAndTablets', lg: 'newValueFromDesktop' }} />
 * @comment It can be plugged into any property and has been a life saver for the <Table> components. (e.g. display a cell full-width at order 3, only on tablets)"
 */
export const breakpointFromProperty = <T extends number | string>(
  cssProperty: string | ((value: T) => string),
  value?: BreakpointProperty<T>
) => {
  if (!value) {
    return '';
  } else if (value instanceof Object) {
    return (Object.entries<T>(value) as Array<[keyof BreakpointValues<T>, T]>)
      .map(([breakpointKey, breakpointValue]): string => {
        if (breakpointKey === 'default') {
          return stringifyProperty(cssProperty, breakpointValue);
        } else {
          return `${breakpoints.up(breakpointKey)} {
            ${stringifyProperty(cssProperty, breakpointValue)}
          }`;
        }
      })
      .join('\n');
  } else {
    return stringifyProperty(cssProperty, value);
  }
};
