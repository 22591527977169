import { useRouter } from 'next/dist/client/router';
import type { ReactElement } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

import { Icon } from '@seuk/design-system/atoms';
import { Button } from '@seuk/design-system/molecules';

const Page = styled.div`
  background-color: ${({ theme }) => theme.palette.background.surface};
  padding-top: 6rem;
  padding-bottom: 9.25rem;
`;

// calc's are used to handle the issue with centering without right margin.
const Container = styled.section`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;

  margin-left: auto;
  margin-right: 0;

  padding-left: 1rem;

  @media screen and (min-width: 640px) {
    max-width: calc(((100% - 640px) / 2) + 640px);
  }

  @media screen and (min-width: 768px) {
    max-width: calc(((100% - 768px) / 2) + 768px);
  }

  @media screen and (min-width: 1024px) {
    max-width: calc(((100% - 1024px) / 2) + 1024px);
  }

  @media screen and (min-width: 1168px) {
    max-width: calc(((100% - 1168px) / 2) + 1168px);
  }

  @media screen and (min-width: 1536px) {
    max-width: calc(((100% - 1536px) / 2) + 1536px);
  }
`;

const InformationSection = styled.section`
  min-width: min(33.75rem, 100%);
  margin-right: 0.5rem;
  margin-bottom: 2.125rem;
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.heading[3].normal};
  color: ${({ theme }) => theme.palette.text.onSurface.strong};
  ${({ theme }) => theme.notch()};
`;
const Description = styled.p`
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.typography.paragraph.medium.normal};
  color: ${({ theme }) => theme.palette.text.onSurface.strong};
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }

  & > * {
    margin-right: 1rem;

    @media screen and (max-width: 640px) {
      width: 100%;

      &:not(:first-of-type) {
        margin-top: 1rem;
      }
    }
  }
`;

interface UnderConstructionProps {
  title: string;
  description: string;
}

export function UnderConstruction({ description, title }: UnderConstructionProps): ReactElement {
  const router = useRouter();
  const navigateToHome = useCallback(async () => await router.replace('/'), [router]);

  return (
    <Page>
      <Container>
        <InformationSection>
          <Title>
            <Icon name="Warning2" /> {title}
          </Title>
          <Description>{description}</Description>
          <ActionsContainer>
            <Button onClick={navigateToHome} color="primary">
              Go to home page
            </Button>
          </ActionsContainer>
        </InformationSection>
      </Container>
    </Page>
  );
}
