import { memo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@seuk/design-system/molecules';

const Wrapper = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  z-index: 5;
  left: ${({ theme }) => theme.spacing(8)};
  top: ${({ theme }) => theme.spacing(8)};
  transform: translateX(-100vw);
  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: translateX(0);
    `};
`;

const SkipToMain = memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const onClick = useCallback(() => {
    document.querySelector('main').scrollIntoView();
    setIsVisible(false);
  }, []);

  const onFocus = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <Wrapper $isVisible={isVisible}>
      <Button color="primary" onClick={onClick} onFocus={onFocus} onBlur={onBlur}>
        Skip to main
      </Button>
    </Wrapper>
  );
});

SkipToMain.displayName = 'SkipToMain';

export default SkipToMain;
