import type { DOMAttributes, PropsWithChildren, HTMLAttributes } from 'react';
import styled from 'styled-components';

type WhiteSpace =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-wrap'
  | 'pre-line'
  | 'break-spaces'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'revert-layer'
  | 'unset';
type TextOverflow = 'clip' | 'ellipsis' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset';
type Transform = 'capitalize' | 'uppercase' | 'lowercase' | 'none';
type TextAlign = 'left' | 'center' | 'right' | 'justify';
type Variant = 'basic' | 'lead' | 'small' | 'extra-small' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'link';
type Component = 'span' | 'p' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'dt' | 'dd' | 'ul';
type Weight = 'normal' | 'medium' | 'semibold' | 'bold';

const cssTextOverflow = (textOverflow?: TextOverflow) => `
  text-overflow: ${textOverflow};
  overflow: hidden;
`;

interface StyledTypographyProps extends HTMLAttributes<HTMLSpanElement> {
  color?: string;
  transform?: Transform;
  textAlign?: TextAlign;
  fontStyle?: 'italic';
  whiteSpace?: WhiteSpace;
  textOverflow?: TextOverflow;
  notch?: boolean;
}
export interface TypographyProps extends StyledTypographyProps {
  variant?: Variant;
  component?: Component;
  weight?: Weight;
  className?: string;
}

const TypographyRoot = styled.span<StyledTypographyProps>`
  margin: 0;
  padding: 0;

  color: ${({ color }) => color ?? 'currentColor'};
  text-transform: ${({ transform }) => transform};
  text-align: ${({ textAlign }) => textAlign};
  ${({ notch, theme }) => notch && theme.notch};

  white-space: ${({ whiteSpace }) => whiteSpace ?? 'inherit'};

  ${({ textOverflow }) => textOverflow && cssTextOverflow(textOverflow)};

  &.h1 {
    font: 32px/40px ShellBold;
    ${({ theme }) => theme.breakpoints.up('md')} {
      & {
        font: 40px/48px ShellBold;
      }
    }
  }

  &.h2 {
    font: 28px/34px ShellBold;
    ${({ theme }) => theme.breakpoints.up('md')} {
      & {
        font: 32px/40px ShellBold;
      }
    }
  }

  &.h3 {
    font: 24px/29px ShellBold;
    ${({ theme }) => theme.breakpoints.up('md')} {
      & {
        font: 28px/34px ShellBold;
      }
    }
  }

  &.h4 {
    font: 18px/23px ShellBold;
    ${({ theme }) => theme.breakpoints.up('md')} {
      & {
        font: 21px/28px ShellBold;
      }
    }
  }

  &.h5 {
    font: 16px/21px ShellBold;
    ${({ theme }) => theme.breakpoints.up('md')} {
      & {
        font: 18px/26px ShellBold;
      }
    }
  }

  &.lead {
    font: 18px/28px ShellBook;
  }

  &.basic {
    font: 16px/24px ShellBook;
  }

  &.small {
    font: 14px/22px ShellBook;
  }

  &.extra-small {
    font: 12px/18px ShellBook;
  }

  &.bold {
    font-family: ShellBold;
  }

  &.semibold {
    font-family: ShellHeavy;
    font-weight: 600;

    & b {
      font-family: ShellBold;
    }
  }

  &.normal {
    font-family: ShellBook;
  }

  &.medium {
    font-family: ShellMedium;
    & b {
      font-family: ShellHeavy;
    }
  }

  &.link {
    text-decoration: underline;
    font: 18px/28px ShellBook;
    color: ${({ theme }) => theme.palette.focus.strong};
  }

  & b {
    font-family: ShellMedium;
  }

  &.italic {
    font-style: italic;
  }
`;

export const Typography = ({
  component,
  className,
  variant,
  children,
  weight,
  fontStyle,
  ...rest
}: PropsWithChildren<TypographyProps> & DOMAttributes<HTMLDivElement>) => {
  return (
    <TypographyRoot
      as={component}
      className={`${variant ?? ''} ${weight ?? ''} ${className ?? ''} ${fontStyle ?? ''}`}
      {...rest}
    >
      {children}
    </TypographyRoot>
  );
};
