import styled from 'styled-components';

import type { MarketingPageFields } from '@seuk/cms-api-client';
import { Container } from '@seuk/design-system/atoms';

export const Section = styled.section<{ grey?: boolean }>`
  padding: ${({ theme }) => theme.spacing(12)} 0;
  background: ${({ theme, grey }) => (grey ? theme.palette.background.base : theme.palette.background.surface)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(22)} 0;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background.surface};

  > ${Section}:nth-of-type(odd) {
    background: ${({ theme }) => theme.palette.background.base};

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-left: ${({ theme }) => theme.spacing(11)};
      margin-right: ${({ theme }) => theme.spacing(11)};
      padding-left: ${({ theme }) => theme.spacing(8)};
      padding-right: ${({ theme }) => theme.spacing(8)};
    }

    &:last-child {
      border-bottom: ${({ theme }) => theme.spacing(12)} solid white;
    }
  }
`;

export const Column = styled.div`
  position: relative;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex: 1;
  }
`;

export const TextWithMargin = styled.p<{ mb?: number }>`
  ${({ theme }) => theme.typography.paragraph.medium.normal};
  margin-bottom: ${({ theme, mb }) => theme.spacing(mb * 0.75)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme, mb }) => theme.spacing(mb)};
  }
`;

const DEFAULT_BACKGROUND_COLOR: MarketingPageFields['introBackgroundColor'] = 'Grey';
export const HeroQuestion = styled.div<{ backgroundColor?: MarketingPageFields['introBackgroundColor'] }>`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 15%;
  background: ${({ theme, backgroundColor = DEFAULT_BACKGROUND_COLOR }) =>
    backgroundColor === DEFAULT_BACKGROUND_COLOR ? theme.palette.background.base : theme.palette.focus.strong};
  color: ${({ theme, backgroundColor = DEFAULT_BACKGROUND_COLOR }) =>
    backgroundColor === DEFAULT_BACKGROUND_COLOR ? theme.palette.focus.strong : theme.palette.background.surface};
  border-radius: ${({ theme }) => theme.radius};
  position: relative;
  padding: 2rem 1.5rem;
  box-sizing: border-box;
  margin: -3rem auto 1.5rem;
  width: ${({ backgroundColor = DEFAULT_BACKGROUND_COLOR }) =>
    backgroundColor === DEFAULT_BACKGROUND_COLOR ? '100%' : 'calc(100% - 4rem)'};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: ${({ theme }) => theme.spacing(6)};
    width: ${({ backgroundColor = DEFAULT_BACKGROUND_COLOR }) =>
      backgroundColor === DEFAULT_BACKGROUND_COLOR ? '100%' : 'calc(100% - 2rem)'};
  }

  h2 {
    margin: 0;
    font: 32px/40px ShellBold;
    ${({ theme }) => theme.breakpoints.up('lg')} {
      max-width: 45%;
      ${({ theme }) => theme.typography.heading[1].normal}
    }
  }

  p {
    margin: 0;
    flex: 1;
    color: ${({ theme }) => theme.palette.text.onSurface.strong};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 3.5rem;
    margin: -9.5rem auto -1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const FormContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    align-items: flex-end;
  }
`;
