import { useContext } from 'react';

import { BasePathContext } from '@seuk/util';

export type IconName =
  | 'Charger'
  | 'Chart'
  | 'Customer'
  | 'Gas'
  | 'GreenEnergy'
  | 'Meter'
  | 'Payment'
  | 'ThumbUp'
  | 'Star'
  | 'PersonPlusOutline'
  | 'PersonPlusFilled'
  | 'PersonOutline'
  | 'PersonFilled'
  | 'Help'
  | 'GiftCard'
  | 'Gift'
  | 'ExternalLink'
  | 'Error'
  | 'ErrorOutline'
  | 'Electricity'
  | 'Edit'
  | 'Documents'
  | 'Close'
  | 'Chevron'
  | 'Check'
  | 'Calendar'
  | 'ArrowForward'
  | 'ArrowBack'
  | 'ArrowRight'
  | 'Home'
  | 'Account'
  | 'HelpBubble'
  | 'Alert'
  | 'Search'
  | 'Sun'
  | 'SmartHome'
  | 'Coffee'
  | 'Plant'
  | 'ElectricCar'
  | 'Household'
  | 'Contract'
  | 'HelpDesk'
  | 'Contact'
  | 'Phone'
  | 'ClockOutline'
  | 'AlertOutline'
  | 'CheckOutline'
  | 'DownloadOutline'
  | 'CoffeePrice'
  | 'Info'
  | 'Danger'
  | 'Warning'
  | 'Plus'
  | 'Minus'
  | 'Wind'
  | 'Logout'
  | 'DangerOutline'
  | 'ElectricityAlert'
  | 'Switching'
  | 'Netherlands'
  | 'Producten'
  | 'Warning2'
  | 'Night'
  | 'Day'
  | 'RomanNr1'
  | 'RomanNr2';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName;
  color?: string;
  className?: string;
}
type IconsDataType = {
  [icon in IconName]: { viewbox: string; properties?: ('fill' | 'stroke')[] };
};

/**
 * helper to create a viewbox
 *
 * motivation: removing sonarQube warning -- reducing codesize
 * @param width the viewbox width
 * @param height [OPTIONAL] the viewbox height -- default: width
 * @returns the viewbox property as a string
 */
const createViewBox = (width: number, height?: number) => `0 0 ${width} ${height ?? width}`;

const IconsData: IconsDataType = {
  Chart: { viewbox: createViewBox(72) },
  Gas: { viewbox: createViewBox(60) },
  GreenEnergy: { viewbox: createViewBox(100) },
  Customer: { viewbox: createViewBox(60) },
  Meter: { viewbox: createViewBox(72) },
  Payment: { viewbox: createViewBox(72) },
  ThumbUp: { viewbox: createViewBox(60) },
  Star: { viewbox: createViewBox(24) },
  PersonPlusOutline: { viewbox: createViewBox(29, 27) },
  PersonPlusFilled: { viewbox: createViewBox(29, 27) },
  PersonOutline: { viewbox: createViewBox(15, 27) },
  PersonFilled: { viewbox: createViewBox(15, 27) },
  Help: { viewbox: createViewBox(24) },
  Gift: { viewbox: createViewBox(24), properties: ['stroke'] },
  GiftCard: { viewbox: createViewBox(100) },
  ExternalLink: { viewbox: createViewBox(24) },
  Error: { viewbox: createViewBox(20) },
  ErrorOutline: { viewbox: createViewBox(20) },
  Electricity: { viewbox: createViewBox(72) },
  Edit: { viewbox: createViewBox(20) },
  Documents: { viewbox: createViewBox(24) },
  Close: { viewbox: createViewBox(20) },
  Chevron: { viewbox: createViewBox(19, 11) },
  Check: { viewbox: createViewBox(18, 14) },
  Calendar: { viewbox: createViewBox(24), properties: ['stroke'] },
  ArrowForward: { viewbox: createViewBox(24) },
  ArrowRight: { viewbox: createViewBox(24, 15) },
  ArrowBack: { viewbox: createViewBox(24) },
  Account: { viewbox: createViewBox(25, 24) },
  Home: { viewbox: createViewBox(24) },
  HelpBubble: { viewbox: createViewBox(49), properties: ['stroke'] },
  Alert: { viewbox: createViewBox(24, 22) },
  Search: { viewbox: createViewBox(24) },
  Sun: { viewbox: createViewBox(96) },
  SmartHome: { viewbox: createViewBox(96) },
  Charger: { viewbox: createViewBox(100) },
  Coffee: { viewbox: createViewBox(100) },
  ElectricCar: { viewbox: createViewBox(60) },
  Plant: { viewbox: createViewBox(60) },
  Household: { viewbox: createViewBox(60), properties: ['fill', 'stroke'] },
  Contract: { viewbox: createViewBox(60) },
  Contact: { viewbox: createViewBox(46, 45), properties: ['fill', 'stroke'] },
  HelpDesk: { viewbox: createViewBox(54) },
  ClockOutline: { viewbox: createViewBox(21) },
  AlertOutline: { viewbox: createViewBox(20, 22), properties: ['fill', 'stroke'] },
  CheckOutline: { viewbox: createViewBox(21), properties: ['fill'] },
  DownloadOutline: { viewbox: createViewBox(17, 20) },
  Phone: { viewbox: createViewBox(72), properties: ['fill', 'stroke'] },
  CoffeePrice: { viewbox: createViewBox(67) },
  Info: { viewbox: createViewBox(20) },
  Danger: { viewbox: createViewBox(22, 20) },
  Warning: { viewbox: createViewBox(20) },
  DangerOutline: { viewbox: createViewBox(21, 18) },
  Logout: { viewbox: createViewBox(18) },
  Plus: { viewbox: createViewBox(25, 26) },
  Minus: { viewbox: createViewBox(25, 25) },
  Wind: { viewbox: createViewBox(72) },
  ElectricityAlert: { viewbox: createViewBox(44, 55) },
  Switching: { viewbox: createViewBox(60) },
  Netherlands: { viewbox: createViewBox(14, 18) },
  Producten: { viewbox: createViewBox(18) },
  Warning2: { viewbox: createViewBox(24) },
  Night: { viewbox: createViewBox(14) },
  Day: { viewbox: createViewBox(21) },
  RomanNr1: { viewbox: createViewBox(6, 21) },
  RomanNr2: { viewbox: createViewBox(11, 12) },
};

export const Icon = ({ name, color, className, height, width, ...rest }: IconProps) => {
  const viewBox = IconsData[name]?.viewbox || createViewBox(24);
  const [, , defaultWidth, defaultHeight] = viewBox.split(' ');
  const properties = IconsData[name]?.properties || ['fill'];
  const fillProperties = {
    fill: properties.includes('fill') ? color || 'black' : 'none',
    stroke: properties.includes('stroke') ? color || 'black' : 'none',
  };
  const basePath = useContext(BasePathContext);

  return (
    <svg
      viewBox={viewBox}
      width={width || defaultWidth}
      height={height || defaultHeight}
      className={className}
      {...fillProperties}
      {...rest}
    >
      <use href={`${basePath}/images/icon-system.svg#${name}`} />
    </svg>
  );
};
