import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type { ReactElement } from 'react';

import { isDocumentUrl, isExternalUrl } from '@seuk/util';

export interface MaybeExternalLinkProps extends LinkProps {
  href: string;
  children: ReactElement;
}

export const MaybeExternalLink = ({ children, href, ...props }: MaybeExternalLinkProps) => {
  return isDocumentUrl(href) || isExternalUrl(href) ? (
    children
  ) : (
    <Link href={href} passHref legacyBehavior {...props}>
      {children}
    </Link>
  );
};
