import { useState } from 'react';
import { useTheme } from 'styled-components';

import { Typography } from '../../atoms';
import { Collapse } from '../../molecules';

import type { HeaderLinksInterface } from './app-header';
import { NavLink } from './nav-link';
import { NavigationButtonParent, NavigationButtonTitle, IconContainer, StyledIcon } from './styled-components';

export interface DropDownNavProps {
  link: HeaderLinksInterface;
  isMobile?: boolean;
  onLinkClick?: () => void;
}

export function DropDownNav({ link, isMobile = false, onLinkClick }: DropDownNavProps) {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavigationButtonParent
      tabIndex={0}
      isOpened={isOpen}
      onFocus={() => {
        setIsOpen(true);
      }}
      onBlur={() => setIsOpen(false)}
      onPointerEnter={(event) => {
        event.pointerType !== 'touch' && setIsOpen(true);
      }}
      onPointerLeave={(event) => {
        event.pointerType !== 'touch' && setIsOpen(false);
      }}
      onTouchEnd={() => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
      }}
    >
      <NavigationButtonTitle isOpened={isOpen} isActive={link.active} isMobile={isMobile}>
        {isMobile ? (
          <>
            <IconContainer>
              {link.icon && <StyledIcon name={link.icon} color={theme.palette.text.onSurface.strong} />}
            </IconContainer>
            <Typography weight="semibold" variant="h5" color={theme.palette.text.onSurface.strong}>
              {link.text}
            </Typography>
          </>
        ) : (
          link.text
        )}
      </NavigationButtonTitle>
      <Collapse isOpen={isOpen}>
        <ul>
          {link?.subItems?.map((subLink) => (
            <NavLink key={subLink.href} link={subLink} onLinkClick={onLinkClick} isMobile={isMobile} isSubMenu />
          ))}
        </ul>
      </Collapse>
    </NavigationButtonParent>
  );
}
