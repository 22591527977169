import { useState } from 'react';
import styled from 'styled-components';

import { useOnMount } from '@seuk/util';

const ShellEnergyCopyrightText = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0;
  }
`;

interface CopyrightProps {
  className?: string;
}

export const Copyright = ({ className }: CopyrightProps) => {
  const [year, setYear] = useState('');

  useOnMount(() => {
    // Setting copyright year on client-side to avoid hydration errors
    setYear(new Date().getFullYear().toString());
  });

  return (
    <ShellEnergyCopyrightText className={className}>
      <p>
        © 2020 - {year} Shell Energy UK Limited The Registered Office of Shell Energy UK Limited is Shell Centre,
        London, United Kingdom, SE1 7NA.
      </p>
      <p>UK Registered in England & Wales No. 07489042 | VAT number: GB 340 0347 49</p>
    </ShellEnergyCopyrightText>
  );
};
