import { useRouter } from 'next/router';
import { useMemo } from 'react';

import type { HeaderLinksInterface } from '@seuk/design-system/templates';
import { AppHeader } from '@seuk/design-system/templates';
import { globalData, Route, RoutesInfo } from '@seuk/shared-data';
import { pathToReactRoute, valueToEnum } from '@seuk/util';

const SECTIONS = globalData.layoutData.menu.map((item) => ({
  text: item.label,
  href: item.href,
  subItems:
    item?.items?.map((subItem) => ({
      text: subItem.label,
      href: subItem.href,
    })) ?? [],
}));

const LEGAL_LINKS = globalData.layoutData.footer.legalLinks.map((legalLink) => ({
  ...legalLink,
  href: `${process.env.NEXT_PUBLIC_WEBSITE_URL ?? ''}${legalLink.href}`,
}));

export const Header = () => {
  const router = useRouter();

  const links: HeaderLinksInterface[] = useMemo(
    () =>
      SECTIONS.map((section) => ({
        ...section,
        active: router.asPath === section.href || section?.subItems?.some((e) => e.href === router.asPath),
        ...RoutesInfo[(valueToEnum(Route, pathToReactRoute(section.href)) as Route) ?? section.href ?? section.text],
        subItems: section.subItems?.map((sub) => ({
          ...sub,
          active: router.asPath === sub.href,
          ...RoutesInfo[(valueToEnum(Route, pathToReactRoute(sub.href)) as Route) ?? sub.href],
        })),
      })),
    [router.asPath]
  );

  return <AppHeader links={links} logoHref={process.env.NEXT_PUBLIC_WEBSITE_URL ?? '/'} legalLinks={LEGAL_LINKS} />;
};
