import styled, { css } from 'styled-components';

export const Container = styled.div<{ shouldExtendOnMobile?: boolean }>`
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  width: 100%;

  ${({ theme }) => {
    const mediaQueries = [];
    for (const breakpoint of theme.breakpoints.keys) {
      const mediaQuery = `
        ${theme.breakpoints.up(breakpoint)} {
          max-width: ${theme.breakpoints.values[breakpoint]}px;
        }
      `;
      mediaQueries.push(mediaQuery);
    }

    return mediaQueries.join('\n');
  }}

  ${({ shouldExtendOnMobile }) =>
    shouldExtendOnMobile &&
    css`
      ${({ theme }) => theme.breakpoints.down('sm')} {
        padding-left: 0;
        padding-right: 0;
      }
    `}
`;
