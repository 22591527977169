import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as CFRichTextTypes from '@contentful/rich-text-types';
import Image from 'next/image';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { isMailTo, isExternalUrl, cmsAssetToImageProps, cmsImageLoader } from '@seuk/util';

export type RichText = CFRichTextTypes.Document | CFRichTextTypes.Block | CFRichTextTypes.Inline;

interface RichTextToReactNodeSettings {
  shouldOpenLinkInNewTab?: (href: string) => boolean;
  useAlphabetizedList?: boolean;
  onClickTrack?: (link: string, text: string) => void;
}

const Text = styled.p`
  white-space: pre-wrap;

  & b {
    font-family: ShellMedium;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const FullPageSizeVideoWrapper = styled.span<{ children?: ReactNode }>`
  display: block;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const richTextToReactNode = (richText: RichText, settings: RichTextToReactNodeSettings = {}): ReactNode =>
  documentToReactComponents(richText as CFRichTextTypes.Document, {
    renderNode: {
      [CFRichTextTypes.INLINES.HYPERLINK]: (node, children) => {
        if ('uri' in node.data) {
          if (node.data.uri.startsWith('https://www.youtube.com/embed/')) {
            const video = (
              <iframe
                src={node.data.uri}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
          picture-in-picture; web-share"
                width="560"
                height="349"
                allowFullScreen
              />
            );
            if (node.data.uri.includes('fullsize=true')) {
              return <FullPageSizeVideoWrapper>{video}</FullPageSizeVideoWrapper>;
            }
            return video;
          }
          return (
            <a
              href={node.data['uri']}
              {...(settings.shouldOpenLinkInNewTab?.(node.data['uri']) ??
              (isExternalUrl(node.data['uri'], process.env['NEXT_PUBLIC_WEBSITE_URL']) || isMailTo(node.data['uri']))
                ? {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  }
                : {})}
              onClick={(e) =>
                settings?.onClickTrack?.(e.currentTarget.href, typeof children === 'string' ? children : '')
              }
            >
              {children}
            </a>
          );
        } else {
          return children;
        }
      },
      [CFRichTextTypes.INLINES.ASSET_HYPERLINK]: (node, children) => {
        const url = node.data?.target?.fields?.file?.url;
        if (url) {
          return (
            <a
              href={url}
              {...(settings.shouldOpenLinkInNewTab?.(url) ?? isExternalUrl(url, process.env['NEXT_PUBLIC_WEBSITE_URL'])
                ? {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    $newPage: true,
                  }
                : {})}
              onClick={(e) =>
                settings?.onClickTrack?.(e.currentTarget.href, typeof children === 'string' ? children : '')
              }
            >
              {children}
            </a>
          );
        } else {
          return children;
        }
      },
      [CFRichTextTypes.BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const props = cmsAssetToImageProps(node.data?.target);
        if (props?.src) {
          return <StyledImage loader={cmsImageLoader} {...props} />;
        }
        return children;
      },
      [CFRichTextTypes.BLOCKS.PARAGRAPH]: (_node, children) => {
        return <Text>{children}</Text>;
      },
      [CFRichTextTypes.BLOCKS.OL_LIST]: (_node, children) => {
        return <ol type={settings.useAlphabetizedList ? 'a' : '1'}>{children}</ol>;
      },
    },
  });

/**
 * Mainly a test utility that converts plain string into RichText paragraph
 */
export const stringToRichText = (value: string): RichText => ({
  nodeType: CFRichTextTypes.BLOCKS.DOCUMENT,
  data: {},
  content: [
    {
      data: {},
      content: [{ nodeType: 'text', value, marks: [], data: {} }],
      nodeType: CFRichTextTypes.BLOCKS.PARAGRAPH,
    },
  ],
});
