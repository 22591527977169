export const ShellEnergyLogo = () => (
  <svg viewBox="0 0 196 48" width="196" height="48" xmlns="http://www.w3.org/2000/svg">
    <title>Shell Energy</title>
    <path
      d="M86.227 6.55611C85.4684 5.92707 84.5243 5.50772 83.5155 5.50772C82.7731 5.50772 81.7643 5.95127 81.7643 6.82223C81.7643 7.73352 82.8699 8.08836 83.5801 8.32223L84.6211 8.63675C86.808 9.28998 88.4947 10.3868 88.4947 12.9351C88.4947 14.4916 88.1234 16.0964 86.8726 17.1529C85.646 18.1932 83.9997 18.6367 82.4099 18.6367C80.4409 18.6367 78.488 17.9755 76.8982 16.8464L78.6494 13.5642C79.6662 14.4513 80.8686 15.1851 82.2647 15.1851C83.2331 15.1851 84.2418 14.7093 84.2418 13.6045C84.2418 12.4593 82.6278 12.0561 81.7563 11.8061C79.19 11.0884 77.5115 10.4109 77.5115 7.38675C77.5115 4.17707 79.7792 2.10449 82.9264 2.10449C84.5162 2.10449 86.453 2.60449 87.841 3.39481L86.227 6.55611Z"
      fill="#DD1D21"
    />
    <path
      d="M93.9257 0.935547V8.88716H93.9822C94.8618 7.7178 95.9189 7.18555 97.4119 7.18555C100.115 7.18555 101.415 8.9678 101.415 11.6855V18.2178H97.6217V12.742C97.6217 11.5001 97.428 10.2017 95.9189 10.2017C94.4099 10.2017 93.9257 11.5001 93.9257 12.742V18.2178H90.1409V0.935547H93.9257Z"
      fill="#DD1D21"
    />
    <path
      d="M114.706 13.5077H106.708C106.708 15.0399 107.523 15.8706 109.089 15.8706C109.896 15.8706 110.477 15.6125 110.889 14.9028H114.536C113.939 17.4189 111.55 18.5641 109.105 18.5641C105.562 18.5641 102.924 16.5641 102.924 12.8786C102.924 9.31411 105.353 7.16895 108.839 7.16895C112.535 7.16895 114.698 9.45927 114.698 13.0964V13.5077H114.706ZM111.171 11.3141C110.985 10.298 110.041 9.63669 109.024 9.63669C107.927 9.63669 107.015 10.2173 106.789 11.3141H111.171Z"
      fill="#DD1D21"
    />
    <path d="M120.008 0.935547H116.215V18.2178H120.008V0.935547Z" fill="#DD1D21" />
    <path d="M126.092 0.935547H122.292V18.2178H126.092V0.935547Z" fill="#DD1D21" />
    <path
      d="M92.8848 23.2666H98.8081L109.662 37.194H109.719V23.2666H115.642V46.0489H109.719L98.8646 32.0892H98.8081V46.0489H92.8848V23.2666Z"
      fill="#0097A9"
    />
    <path
      d="M172.397 23.2666H179.499L183.768 29.4601L188.029 23.2666H195.13L186.73 35.1134V46.0489H180.806V35.1134L172.397 23.2666Z"
      fill="#0097A9"
    />
    <path
      d="M175.165 33.3304C175.101 36.653 174.867 39.4917 172.841 42.3304C170.606 45.4433 167.249 46.7981 163.44 46.7981C156.274 46.7981 151.287 41.9352 151.287 34.7739C151.287 27.3707 156.306 22.5078 163.65 22.5078C168.338 22.5078 171.962 24.6207 173.931 28.8788L168.306 31.2336C167.491 29.0562 165.764 27.6127 163.383 27.6127C159.486 27.6127 157.46 31.3304 157.46 34.8062C157.46 38.3385 159.574 41.9352 163.472 41.9352C166.038 41.9352 168.007 40.6046 168.25 37.9756H163.416V33.3223H175.165V33.3304Z"
      fill="#0097A9"
    />
    <path
      d="M145.42 36.7101C148.656 36.1376 150.407 33.4198 150.407 30.2747C150.407 25.4118 146.993 23.2666 142.49 23.2666H133.638V28.2747H139.416V25.5972L146.558 30.2505L139.416 34.9037V32.0811H133.638V46.0489H139.561V37.2827H139.626L145.218 46.0408H152.594L145.42 36.7101Z"
      fill="#0097A9"
    />
    <path
      d="M83.459 28.2827V32.0892H90.1408V37.1053H83.459V41.0327H90.504V46.0489H77.5356V23.2666H90.504V28.2827H83.459Z"
      fill="#0097A9"
    />
    <path
      d="M124.212 28.2827V32.0892H130.894V37.1053H124.212V41.0327H131.257V46.0489H118.289V23.2666H131.257V28.2827H124.212Z"
      fill="#0097A9"
    />
    <path
      d="M22.9509 45.8711H10.2569L9.07868 36.4679L1.37999 30.8711C1.04105 29.2259 0.871582 27.5485 0.871582 25.8711C0.871582 12.0727 12.0807 0.871094 25.8883 0.871094C39.6959 0.871094 50.905 12.0727 50.905 25.8711C50.905 27.5485 50.7356 29.2259 50.4047 30.8711L42.706 36.4679L41.5278 45.8711H28.8338L28.0995 46.4033C27.4539 46.8711 26.6872 47.1211 25.8883 47.1211C25.0975 47.1211 24.3227 46.8711 23.6852 46.4033L22.9509 45.8711Z"
      fill="#FBCE07"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9509 45.8789H10.2569L9.07868 36.4757L1.37999 30.8789C1.04105 29.2337 0.871582 27.5563 0.871582 25.8789C0.871582 12.0805 12.0807 0.878906 25.8883 0.878906C39.6959 0.878906 50.905 12.0805 50.905 25.8789C50.905 27.5563 50.7356 29.2337 50.3966 30.8789L42.6979 36.4757L41.5197 45.8789H28.8338L28.0995 46.4112C27.4539 46.8789 26.6872 47.1289 25.8883 47.1289C25.0975 47.1289 24.3227 46.8789 23.6852 46.4112L22.9509 45.8789ZM23.3463 41.4999H14.1224L13.1943 34.0483L5.59248 28.5241C5.36652 27.6612 5.25354 26.766 5.25354 25.8708C5.25354 24.3789 5.56827 22.9031 6.17351 21.5402L19.6261 35.137L6.5528 18.9999C7.06927 16.9031 8.20713 15.0241 9.82111 13.5886L21.5467 33.8144L11.1526 11.6692C12.5003 9.87891 14.3725 8.56439 16.5111 7.9031L23.6852 33.0079L18.4398 6.87084C20.0376 5.97568 21.8372 5.49987 23.661 5.49987C24.0484 5.49987 24.4357 5.52407 24.8231 5.56439L25.8883 32.7499L26.9616 5.56439C27.349 5.52407 27.7363 5.49987 28.1237 5.49987C29.9555 5.49987 31.7551 5.97568 33.3449 6.87084L28.1075 33.0079L35.2736 7.9031C37.4121 8.56439 39.2844 9.87891 40.632 11.6692L30.238 33.8144L41.9636 13.5886C43.5775 15.0241 44.7154 16.9112 45.2319 18.9999L32.1506 35.137L45.6112 21.5402C46.2164 22.9031 46.5311 24.3789 46.5311 25.8708C46.5311 26.766 46.4182 27.6531 46.1922 28.5241L38.5903 34.0483L37.6623 41.4999H28.4384L27.0746 42.4918C26.7276 42.7418 26.316 42.8708 25.8964 42.8708C25.4767 42.8708 25.0652 42.7337 24.7182 42.4918L23.3463 41.4999Z"
      fill="#DD1D21"
    />
  </svg>
);
