import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { isExternalUrl } from '@seuk/util';

import { MaybeExternalLink, Typography } from '../../atoms';

import type { HeaderLinksInterface } from './app-header';
import {
  NavigationButtonLink,
  IconContainer,
  StyledIcon,
  NavigationButtonLinkSubMenu,
  OpenNewTabIcon,
} from './styled-components';

export interface NavLinkProps {
  link: Omit<HeaderLinksInterface, 'subItems'>;
  isSubMenu?: boolean;
  className?: string;
  onLinkClick?: () => void;
  isMobile?: boolean;
}

export function NavLink({ link, isSubMenu, className, onLinkClick, isMobile = false }: NavLinkProps) {
  const theme = useTheme();

  const LinkBody = isMobile ? (
    <>
      <IconContainer>
        {link.icon && <StyledIcon name={link.icon} color={theme.palette.text.onSurface.strong} />}
      </IconContainer>
      <Typography
        weight={isSubMenu ? 'medium' : 'semibold'}
        variant={isSubMenu ? 'small' : 'h5'}
        color={theme.palette.text.onSurface.strong}
      >
        {link.text}
      </Typography>
    </>
  ) : (
    link.text
  );

  const isExternalLink = useMemo(
    () => isExternalUrl(link.href ?? '', process.env['NEXT_PUBLIC_WEBSITE_URL']),
    [link.href]
  );

  return (
    <li>
      <MaybeExternalLink href={link.href ?? ''}>
        {isSubMenu ? (
          <NavigationButtonLinkSubMenu
            as="a"
            onClick={() => onLinkClick?.()}
            href={link.href ?? ''}
            isActive={link.active}
            className={className}
          >
            {LinkBody}
          </NavigationButtonLinkSubMenu>
        ) : (
          <NavigationButtonLink
            as="a"
            onClick={() => onLinkClick?.()}
            href={link.href ?? ''}
            isActive={link.active}
            className={className}
            target={isExternalLink ? '_blank' : '_self'}
            rel="noopener noreferrer"
          >
            {LinkBody}
            {isExternalLink && <OpenNewTabIcon />}
          </NavigationButtonLink>
        )}
      </MaybeExternalLink>
    </li>
  );
}
