import type { Asset } from 'contentful';
import type { ImageLoader, ImageProps } from 'next/image';

import { isCMSImageCDNUrl } from '@seuk/util';

export const cmsImageLoader: ImageLoader = ({ width, quality, src }) =>
  isCMSImageCDNUrl(src) ? `https:${src}?w=${width}&q=${quality ?? 75}` : src;

export const cmsAssetToImageProps = (asset: Asset | undefined): ImageProps | null =>
  asset && asset.fields.file
    ? {
        ...asset.fields.file.details.image,
        alt: asset.fields.description ?? '',
        src: asset.fields.file.url,
      }
    : null;
