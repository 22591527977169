/**
 * Shamelessly stolen from tns-solaris
 * @see https://github.com/shellagilehub/tns-solaris/blob/master/src/components/flexbox/index.ts
 */

import styled, { css } from 'styled-components';

import type { BreakpointProperty } from '../../theme';

type Align = BaseFlexAlign | 'baseline' | 'stretch';
type AlignContent = BaseFlexAlign | 'space-around' | 'space-between' | 'stretch';
type BaseFlexAlign = 'center' | 'flex-end' | 'flex-start';
type Display = 'flex' | 'inline-flex' | 'none';
type FlexDirection = 'column-reverse' | 'column' | 'row-reverse' | 'row';
type FlexWrap = 'nowrap' | 'wrap-reverse' | 'wrap';
export type JustifyContent = BaseFlexAlign | 'space-around' | 'space-between';

export interface Props {
  readonly alignContent?: AlignContent;
  readonly alignItems?: BreakpointProperty<Align>;
  readonly alignSelf?: BreakpointProperty<Align>;
  readonly display?: BreakpointProperty<Display>;
  readonly flex?: number | string;
  readonly flexBasis?: number | string;
  readonly flexDirection?: BreakpointProperty<FlexDirection>;
  readonly flexGrow?: number | string;
  readonly flexShrink?: number | string;
  readonly flexWrap?: FlexWrap;
  readonly justifyContent?: BreakpointProperty<JustifyContent>;
  readonly order?: BreakpointProperty<number>;
  readonly gap?: BreakpointProperty<string>;
}

export const Flexbox = styled.div.attrs<Props>(({ flexBasis, flexGrow, flexShrink }) => ({
  style: { flexBasis, flexGrow, flexShrink },
}))<Props>`
  ${({
    theme,
    order,
    gap,
    display,
    alignItems,
    justifyContent,
    alignSelf,
    flexWrap,
    alignContent,
    flex,
    flexDirection,
  }) => css`
    ${theme.breakpointFromProperty('order', order)};
    ${theme.breakpointFromProperty('gap', gap)};
    ${theme.breakpointFromProperty('display', display ?? 'flex')};

    ${theme.breakpointFromProperty('align-items', alignItems)};
    ${theme.breakpointFromProperty('justify-content', justifyContent)};
    ${theme.breakpointFromProperty('align-self', alignSelf)};

    ${theme.breakpointFromProperty('flex-direction', flexDirection)};
    flex-wrap: ${flexWrap};
    align-content: ${alignContent};

    flex: ${
      /* This and any other shorthand property should not be placed in .attrs without proper processing due to how other values affect it */
      flex
    };
  `}
`;
