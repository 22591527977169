import { memo } from 'react';
import styled from 'styled-components';

import { isDocumentUrl, isExternalUrl } from '@seuk/util';

import { Flexbox, Icon, MaybeExternalLink } from '../../atoms';

import type { AppFooterProps } from './app-footer';
import { Copyright } from './copyright';

declare const evidon: {
  notice: {
    showOptions: () => void;
  };
};

const LegalLinks = styled(Flexbox)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const LocalLink = styled.a`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.paragraph.small.normal};
  color: ${({ theme }) => theme.palette.text.onSurface.subtle};
  text-decoration: underline;
  cursor: pointer;
`;

const Footer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};
  border-top: 1px solid ${({ theme }) => theme.palette.text.onSurface.subtle};
  width: 100%;
`;

export interface AppFooterMobileProps {
  legalLinks: AppFooterProps['legalLinks'];
}

export const AppFooterMobile = memo(({ legalLinks }: AppFooterMobileProps) => {
  return (
    <Footer>
      <LegalLinks>
        <LocalLink
          role="button"
          data-testid="cookie-preference-mobile"
          onClick={() => {
            if (evidon !== undefined) {
              evidon.notice?.showOptions();
            }
          }}
        >
          Manage my cookies
        </LocalLink>
        {legalLinks.map((link) => (
          <MaybeExternalLink href={link.href} prefetch={false} key={link.href}>
            <LocalLink
              href={link.href}
              {...(isDocumentUrl(link.href) || isExternalUrl(link.href)
                ? { target: '_blank', rel: 'noopener noreferrer' }
                : {})}
            >
              <Icon name="Documents" /> {link.label}
            </LocalLink>
          </MaybeExternalLink>
        ))}
      </LegalLinks>

      <Copyright />
    </Footer>
  );
});

AppFooterMobile.displayName = 'AppFooterMobile';
