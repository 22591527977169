import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import styled, { css } from 'styled-components';
import 'react-phone-number-input/style.css';

import type { RichText } from '@seuk/cms-api-client';
import { richTextToReactNode } from '@seuk/cms-api-client';
import { AnimatedContainer } from '@seuk/design-system/atoms';
import { NotificationBar } from '@seuk/design-system/molecules';
import { AppFooter } from '@seuk/design-system/templates';
import { WEBSITE_SEO, globalData } from '@seuk/shared-data';

import { Header } from '../../components';
import SkipToMain from '../../components/skip-to-main/skip-to-main';

const FOOTER_SECTIONS = globalData.layoutData.footer.sections.map((section) => ({
  title: section.title,
  items: section.items.map((item) => ({
    label: item.label,
    href: item.href,
  })),
}));

const Container = styled.div`
  ${({ theme }) => css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.background.surface};
  `}
`;

type Props = AppProps<{ translations: Translations }>;

export default function CustomApp({ Component, pageProps }: Props) {
  const router = useRouter();

  const pageUrl = process.env.NEXT_PUBLIC_WEBSITE_URL + router.asPath.split('?')[0];

  return (
    <>
      <DefaultSeo {...{ ...WEBSITE_SEO, openGraph: { ...WEBSITE_SEO.openGraph, url: pageUrl } }} canonical={pageUrl} />
      <Container id="root">
        <SkipToMain />
        <Header />
        {globalData.layoutData.globalAlert ? (
          <AnimatedContainer>
            <NotificationBar>{richTextToReactNode(globalData.layoutData.globalAlert as RichText)}</NotificationBar>
          </AnimatedContainer>
        ) : null}

        <Component {...pageProps} />

        <AppFooter sections={FOOTER_SECTIONS} legalLinks={globalData.layoutData.footer.legalLinks} />
      </Container>
    </>
  );
}
