type IconName =
  | 'Charger'
  | 'Chart'
  | 'Customer'
  | 'Gas'
  | 'GreenEnergy'
  | 'Meter'
  | 'Payment'
  | 'ThumbUp'
  | 'Star'
  | 'PersonPlusOutline'
  | 'PersonPlusFilled'
  | 'PersonOutline'
  | 'PersonFilled'
  | 'Help'
  | 'GiftCard'
  | 'Gift'
  | 'ExternalLink'
  | 'Error'
  | 'ErrorOutline'
  | 'Electricity'
  | 'Edit'
  | 'Documents'
  | 'Close'
  | 'Chevron'
  | 'Check'
  | 'Calendar'
  | 'ArrowForward'
  | 'ArrowBack'
  | 'ArrowRight'
  | 'Home'
  | 'Account'
  | 'HelpBubble'
  | 'Alert'
  | 'Search'
  | 'Sun'
  | 'SmartHome'
  | 'Coffee'
  | 'Plant'
  | 'ElectricCar'
  | 'Household'
  | 'Contract'
  | 'HelpDesk'
  | 'Contact'
  | 'Phone'
  | 'ClockOutline'
  | 'AlertOutline'
  | 'CheckOutline'
  | 'DownloadOutline'
  | 'CoffeePrice'
  | 'Info'
  | 'Danger'
  | 'Warning'
  | 'Plus'
  | 'Minus'
  | 'Wind'
  | 'Logout'
  | 'DangerOutline'
  | 'ElectricityAlert'
  | 'Switching'
  | 'Netherlands'
  | 'Producten';

export enum Route {
  Home = '/',
  BusinessEnergy = 'Business energy',
  EnergySolutions = '/energy-solutions/',
  EnergyInsights = '/energy-insights/',
  RequestAQuote = '/request-a-quote/',
}

type RouteInfo = {
  pageName: string;
  icon?: IconName;
};
export const RoutesInfo: Record<Route | 'UnknownRoute', RouteInfo> = {
  [Route.Home]: {
    pageName: 'home',
    icon: 'Home',
  },
  [Route.BusinessEnergy]: {
    pageName: 'business_energy',
    icon: 'Producten',
  },
  [Route.EnergySolutions]: {
    pageName: 'energy_solutions',
    icon: 'ElectricCar',
  },
  [Route.EnergyInsights]: {
    pageName: 'energy_insights',
    icon: 'GreenEnergy',
  },
  [Route.RequestAQuote]: {
    pageName: 'request_a_quote',
    icon: 'Meter',
  },
  UnknownRoute: {
    pageName: 'unknown',
  },
};
